import { Col, Row, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import cloudpractitioner from "../assets/img/cloud-practitioner.png";
import solutionsarchitect from "../assets/img/solutionsarchitect.png";
import excellogo from "../assets/img/excellogo2.png";
import pythonlogo from "../assets/img/python.png";
import sqllogo from "../assets/img/sqllogo.png";
import reactlogo from "../assets/img/react-icon.png";
import securityspecialty from "../assets/img/securityspecialty.png";
import developerassociate from "../assets/img/developerassociate.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className='skill' id='skills'>
      <Container>
        <Row>
          <Col>
            <div className='skill-bx'>
              <h2>
                Skills
              </h2>
              <p></p>
              <Carousel responsive={responsive} infinite={true} className="skill-slider">
                <div className='item'>
                  <a href="https://www.credly.com/badges/5e15b20f-e8ff-4391-8124-d459d66e29df/public_url"><img src={developerassociate} alt='awsassociate'/></a>
                  <h5>AWS Certified<br/>Developer - Associate</h5>
                </div>
                <div className='item'>
                  <a href="https://www.credly.com/badges/5e15b20f-e8ff-4391-8124-d459d66e29df/public_url"><img src={solutionsarchitect} alt='awsassociate'/></a>
                  <h5>AWS Certified<br/>Solutions Architect - Associate</h5>
                </div>
                <div className='item'>
                  <a href="https://www.credly.com/badges/f3c04240-8b6f-4102-8ee1-7ea3714818f7/public_url"><img src={securityspecialty} alt='securityspecialty'/></a>
                  <h5>AWS Certified<br/>Security - Specialty</h5>
                </div>
                <div className='item'>
                  <img src={pythonlogo} alt='python'/>
                  <h5>Python</h5>
                </div>
                <div className='item'>
                  <img src={sqllogo} alt='sql'/>
                  <h5>SQL</h5>
                </div>
                <div className='item'>
                  <img src={reactlogo} alt='react'/>
                  <h5>React</h5>
                </div>
                <div className='item'>
                  <img src={excellogo} alt='excel'/>
                  <h5>Excel VBA</h5>
                </div>
                <div className='item'>
                  <a href="https://www.credly.com/badges/3af705f5-28e3-4b38-9e93-35f448c64b9f/public_url"><img src={cloudpractitioner} alt='awspractitioner'/></a>
                  <h5>AWS Certified<br/>Cloud Practitioner</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}