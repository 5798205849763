import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import awsarchitecture from '../assets/img/awsdiagram.png'
import { useEffect, useState } from 'react';

export const Projects = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])

  useEffect(() => {
    fetch("https://k5qgx00rrb.execute-api.us-east-1.amazonaws.com/prod")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])
  
  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
      return (
        <section className="project" id="projects">
          <Container>
            <Row>
              <Col size={12}>
                <h2>Cloud Resume Project</h2>
                <p>This website is hosted through AWS</p>
                <img src={awsarchitecture} alt='architecture'/>
                <p>This website has been visited {items.visits} times.</p>
                {/* <p>{typeof(items)}</p>
                <p>{JSON.stringify(items, null, 4)}</p>
                <p>{items.visits}</p> */}
              </Col>
            </Row>
          </Container>
        </section>
      )
    }
}
